import React from "react";
import { useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../../../_components/Loading";
import RequestFailed from "../../../_components/RequestFailed";
import { Global } from "@emotion/react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Input,
  Link,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { Plus, Share } from "lucide-react";
import clsx from "clsx";
import { formatToNaira } from "../../../helpers/utils/funtions";
import { WorkHistory } from "../../../features/pro-portfolio/work-history";
import { Rating } from "../../../features/pro-portfolio/rating";
import { Helmet } from "react-helmet";
import { ProNotFound } from "features/pro-portfolio/pro-not-found";
import { FaFacebookF, FaPaperPlane, FaTwitter, FaUpload } from "react-icons/fa";
import { logEvent } from "../../../helpers/analytics";
import { useAuth0 } from "@auth0/auth0-react";
import { PortfolioBadgeSection } from "../../../features/pro-portfolio/badge-section";
import { getCappedAmount } from "../../../lib/utils";
import { gql } from "../../../__generated__";
import { PortfolioSectionHeading } from "../../../features/pro-portfolio/portfolio-section-heading";
import { CertificationCreator } from "../../../features/pro-portfolio/certification-creator";
import { Certifications } from "../../../features/pro-portfolio/certifications";
import HelmetIcon from "../../../assets/helmet-icon.png";
import TransactionsIcon from "../../../assets/transactions-icon.png";
import Slider from "react-slick";
import { useApplicationConfig } from "context/ApplicationConfig";
import axios from "axios";
import { EducationEntries } from "features/pro-portfolio/education-entries";

const GET_PORTFOLIO = gql(`
  query ProPortfolio($handle: String!) {
    proPortfolio(handle: $handle) {
      firstName
      lastName
      phone
      email
      isEmailHidden
      workHistory {
        id
        name
        description
        budget
        startedAt
        endedAt
        images {
					id
					name
					data
					mimeType
          deletedAt
				}
        videos
        clientReview {
          rating
          feedback
        }
        verified
        isPinned
        createdAt
      }
      backgroundCheckStatus
      identityVerificationStatus
      certifications {
        id
        name
        issuedBy
        issuedAt
        issuerCertificationId
        url
        verified
        createdAt
      }
      educationEntries {
				id
				schoolName
				fieldOfStudy
				qualification
				verified
				createdAt
      }
      title
      location
      totalBudget
      averageRating
      averageLaborHackJobsRatings {
        averageAppearanceRating
        averagePunctualityRating
        averagePostServiceCleanUpRating
        averageBehaviorRating
        averageProperToolUsageRating
      }
      ratingCount
      overview
      experience
      portfolioImages {
        id
        mimeType
        data
				deletedAt
      }
    }
  }
`);

export default function ProProfilePage() {
  const { handle } =
    useParams<{
      handle: string;
    }>();

  const { toggles, loading: togglesAreLoading } = useApplicationConfig();

  const carouselSettings = useBreakpointValue({
    base: {
      slidesToShow: 1,
      dots: true,
    },
    lg: {},
  });

  const { pathname } = useLocation();

  const { getAccessTokenSilently } = useAuth0();

  const url = `${window.location.origin}${pathname}`;

  const [copied, setCopied] = React.useState(false);

  const [showShareModal, setShowShareModal] = React.useState(false);

  const [showCertificationCreateModal, setShowCertificationCreateModal] =
    React.useState(false);

  const [showPortfolioImageUploadModal, setShowPortfolioImageUploadModal] =
    React.useState(false);

  const [selectedPortfolioImage, setSelectedPortfolioImage] =
    React.useState("");

  const [portfolioImageFile, setPortfolioImageFile] = React.useState<File>();

  const [portfolioImageUploadLoading, setPortfolioImageUploadLoading] =
    React.useState(false);

  const [portfolioImageUploadError, setPortfolioImageUploadError] =
    React.useState("");

  // Handle file change and preview generation
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result && typeof reader.result === "string") {
          setSelectedPortfolioImage(reader.result); // Preview for UI
        }
      };

      reader.readAsDataURL(file);

      setPortfolioImageFile(file);
    }
  };

  const closePortfolioImageUploadModal = () => {
    setSelectedPortfolioImage("");
    setPortfolioImageUploadError("");
    setShowPortfolioImageUploadModal(false);
  };

  const handlePortfolioImageUpload = async () => {
    if (!portfolioImageFile) return;

    setPortfolioImageUploadLoading(true);

    const formData = new FormData();

    formData.append("image", portfolioImageFile, portfolioImageFile.name);

    const accessToken = await getAccessTokenSilently();

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/pro-portfolio/upload-portfolio-image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: accessToken,
          Authprovider: "auth0",
        },
      }
    );

    setPortfolioImageUploadLoading(false);

    if (response.data.success) {
      refetch();

      closePortfolioImageUploadModal();

      setPortfolioImageUploadError("");

      return;
    }

    setPortfolioImageUploadError("An error occurred while uploading the image");
  };

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const { data, loading, error, refetch } = useQuery(GET_PORTFOLIO, {
    variables: { handle },
  });

  if (loading || togglesAreLoading) return <Loading />;
  if (error) return <RequestFailed />;

  if (!data) return <RequestFailed />;

  if (!data.proPortfolio) return <ProNotFound />;

  const canEdit = false; // we no longer have edit mode in this repository and cleanups will be done soon.

  const noOfJobsCompleted = data.proPortfolio.workHistory.length;

  const totalBudget = data.proPortfolio.totalBudget;

  const averageBudget = totalBudget > 0 ? totalBudget / noOfJobsCompleted : 0;

  const workHistory = data.proPortfolio.workHistory;

  const workHistoryImages = workHistory
    .map((item) => item?.images)
    .flat()
    .filter((image) => !image?.deletedAt);

  const portfolioMedia = workHistoryImages.map(
    (image) => `data:${image?.mimeType};base64,${image?.data}`
  );

  const shortenedName = `${data.proPortfolio.firstName} ${data.proPortfolio.lastName[0]}.`;

  const handleShare = () => {
    logEvent("pro_portfolio/share_profile_clicked");

    if (!data.proPortfolio) return;

    /**
     * Open the Web Share API
     */
    if (navigator.share) {
      navigator
        .share({
          title: `${shortenedName} | LaborHack Pro`,
          text: `Check out ${shortenedName}'s profile on LaborHack`,
          url,
        })
        .then(() => {
          logEvent("pro_portfolio/profile_shared");
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            return;
          }

          logEvent("pro_portfolio/profile_share_failed", {
            error: error.message,
          });

          setShowShareModal(true);
        });
    } else {
      setShowShareModal(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {data.proPortfolio.firstName} {data.proPortfolio.lastName?.[0]}. |
          LaborHack
        </title>
      </Helmet>
      <Global
        styles={`
       body {
          background-color: white !important;
        }
      `}
      />

      <Modal
        isOpen={showCertificationCreateModal}
        onClose={() => setShowCertificationCreateModal(false)}
        isCentered
        closeOnOverlayClick
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="font-[AeonikPro] !text-2xl">
            Add Certification
          </ModalHeader>
          <Divider />
          <VStack py={4} px={6} alignItems="start">
            <CertificationCreator
              onClose={() => setShowCertificationCreateModal(false)}
            />
          </VStack>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showPortfolioImageUploadModal}
        onClose={closePortfolioImageUploadModal}
        isCentered
        closeOnOverlayClick
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mx={"auto"}>Upload Portfolio Image</ModalHeader>
          <VStack spacing={4} align={"end"} p={6}>
            {selectedPortfolioImage && (
              <div className="flex justify-center items-center w-full">
                <img src={selectedPortfolioImage} alt="Image Preview" />
              </div>
            )}
            <Box position="relative" display="flex" gap={4}>
              {/* Hidden file input */}
              <Input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                id="upload-btn"
                hidden
              />
              <Button
                onClick={() => {
                  document.getElementById("upload-btn")?.click();
                }}
                leftIcon={<FaUpload />}
                colorScheme="prussianBlue"
                variant="outline"
              >
                {selectedPortfolioImage ? "Change" : "Choose File"}
              </Button>
              {selectedPortfolioImage && (
                <Button
                  isLoading={portfolioImageUploadLoading}
                  rightIcon={<FaPaperPlane />}
                  colorScheme="prussianBlue"
                  variant="solid"
                  onClick={() => {
                    handlePortfolioImageUpload();
                  }}
                >
                  Upload
                </Button>
              )}
            </Box>
            {portfolioImageUploadError && (
              <p className="text-red-500">{portfolioImageUploadError}</p>
            )}
          </VStack>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        isCentered
        closeOnOverlayClick
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share Profile</ModalHeader>
          <VStack pb={8} px={6} alignItems="start">
            <HStack>
              <Link
                href={`https://twitter.com/intent/tweet?text=Check out ${data.proPortfolio.firstName} ${data.proPortfolio.lastName[0]} on LaborHack&url=${url}`}
                isExternal
              >
                <Button
                  rightIcon={<FaTwitter />}
                  colorScheme="twitter"
                  variant="solid"
                  onClick={() =>
                    logEvent("pro_portfolio/twitter_share_clicked")
                  }
                >
                  Tweet
                </Button>
              </Link>
              <Link
                href={
                  "https://www.facebook.com/sharer/sharer.php?u=" +
                  encodeURIComponent(url)
                }
                isExternal
              >
                <Button
                  rightIcon={<FaFacebookF />}
                  colorScheme="facebook"
                  variant="solid"
                  onClick={() =>
                    logEvent("pro_portfolio/facebook_share_clicked")
                  }
                >
                  Post to Facebook
                </Button>
              </Link>
            </HStack>
          </VStack>
        </ModalContent>
      </Modal>
      <HStack w="full" bgColor="white" justifyContent="center">
        <Stack
          px={{
            base: 0,
            lg: 5,
          }}
          py={{
            base: 8,
            lg: 20,
          }}
          spacing={{
            base: 12,
            lg: 0,
          }}
          direction={{
            base: "column",
            lg: "row",
          }}
          w="full"
          maxW="container.xl"
          alignItems={{
            base: "start",
            lg: "center",
          }}
        >
          <div className="flex flex-col w-full border-flatteredFlamingo-50 lg:border-[16px]">
            <div className="flex flex-col lg:flex-row lg:justify-between gap-8 border px-6 py-9">
              <div className="flex items-start gap-5">
                <Avatar
                  bg="flatteredFlamingo.50"
                  size={{
                    base: "xl",
                    lg: "2xl",
                  }}
                  name={`${data.proPortfolio.firstName} ${data.proPortfolio.lastName[0]}.`}
                  src={""}
                />
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-1">
                    <Heading
                      as="h2"
                      fontSize={{
                        base: 24,
                        lg: 28,
                      }}
                      fontWeight={500}
                      textColor={"#040A0D"}
                    >
                      {data.proPortfolio.firstName}{" "}
                      {data.proPortfolio.lastName[0]}.
                    </Heading>
                    <p className="font-normal text-xl text-[#464646]">
                      {data.proPortfolio.title}
                    </p>
                  </div>

                  <div className="flex items-center gap-2">
                    <Rating rating={data.proPortfolio.averageRating} />
                    <p className="text-sm">
                      {data.proPortfolio.averageRating &&
                        data.proPortfolio.averageRating.toFixed(2)}{" "}
                      <span className="text-gray-500">
                        ({data.proPortfolio.ratingCount} reviews)
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-6 w-full lg:w-auto">
                <Button
                  leftIcon={<Share className="w-5 h-5" />}
                  colorScheme="prussianBlue"
                  size="lg"
                  variant="ghost"
                  className={clsx("w-full order-2 lg:order-1 lg:w-auto")}
                  onClick={handleShare}
                >
                  Share Profile
                </Button>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 border-l border-r border-b">
              <div className="col-span-1 flex flex-col">
                <div className="p-6 flex flex-col gap-6 border-b items-start">
                  <PortfolioSectionHeading>
                    Contact Information
                  </PortfolioSectionHeading>
                  <div className="flex flex-col gap-4">
                    {!data.proPortfolio.isEmailHidden && (
                      <div className="flex flex-col gap-2">
                        <p className="text-base text-[#6E6E6E] font-[Inter]">
                          Email address
                        </p>
                        <p className="text-lg font-[Inter]">
                          {data.proPortfolio.email}
                        </p>
                      </div>
                    )}
                    <div className="flex flex-col gap-2">
                      <p className="text-base text-[#6E6E6E] font-[Inter]">
                        Phone number
                      </p>
                      <p className="text-lg font-[Inter]">
                        {data.proPortfolio.phone}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-6 grid grid-cols-2 gap-5 border-b items-start">
                  <div>
                    <p className="font-[Inter] text-lg text-[#121212]">
                      Punctuality
                    </p>
                  </div>
                  <Rating
                    rating={
                      data.proPortfolio.averageLaborHackJobsRatings
                        .averagePunctualityRating
                    }
                  />
                  <div>
                    <p className="font-[Inter] text-lg text-[#121212]">
                      Appearance
                    </p>
                  </div>
                  <Rating
                    rating={
                      data.proPortfolio.averageLaborHackJobsRatings
                        .averageAppearanceRating
                    }
                  />
                  <div>
                    <p className="font-[Inter] text-lg text-[#121212]">
                      Behaviour
                    </p>
                  </div>
                  <Rating
                    rating={
                      data.proPortfolio.averageLaborHackJobsRatings
                        .averageBehaviorRating
                    }
                  />
                  <div>
                    <p className="font-[Inter] text-lg text-[#121212]">
                      Proper tool usage
                    </p>
                  </div>
                  <Rating
                    rating={
                      data.proPortfolio.averageLaborHackJobsRatings
                        .averageProperToolUsageRating
                    }
                  />
                  <div>
                    <p className="font-[Inter] text-lg text-[#121212]">
                      Post service clean up
                    </p>
                  </div>
                  <Rating
                    rating={
                      data.proPortfolio.averageLaborHackJobsRatings
                        .averagePostServiceCleanUpRating
                    }
                  />
                </div>
                <div className="p-6 flex gap-5 border-b items-start">
                  <div className="w-[64px] h-[64px]">
                    <img src={HelmetIcon} alt="Helmet Icon" />
                  </div>
                  <div className="flex flex-col gap-3">
                    <Heading
                      as="h3"
                      fontSize={{
                        base: "22px",
                        lg: "26px",
                      }}
                      textColor={"#040A0D"}
                    >
                      {noOfJobsCompleted > 100 ? `100+` : noOfJobsCompleted}
                    </Heading>
                    <p className="text-base lg:text-lg font-normal font-[Inter] text-[#464646]">
                      {noOfJobsCompleted === 1
                        ? "Job completed"
                        : "Jobs completed"}
                    </p>
                  </div>
                </div>
                <div className="p-6 flex gap-5 border-b items-start">
                  <div className="w-[64px] h-[64px]">
                    <img src={TransactionsIcon} alt="Transaction Icon" />
                  </div>
                  <div className="flex flex-col gap-3">
                    <Heading
                      as="h3"
                      fontSize={{
                        base: "22px",
                        lg: "26px",
                      }}
                      textColor={"#040A0D"}
                    >
                      {`${
                        canEdit
                          ? formatToNaira(averageBudget, 2)
                          : formatToNaira(getCappedAmount(averageBudget), 0)
                      }${canEdit ? "" : noOfJobsCompleted > 0 ? "+" : ""}`}
                    </Heading>
                    <p className="text-base lg:text-lg font-normal font-[Inter] text-[#464646]">
                      Average Budget
                    </p>
                  </div>
                </div>
                {canEdit && (
                  <div className="p-6 flex gap-5 border-b items-start">
                    <div className="w-[64px] h-[64px]">
                      <img src={TransactionsIcon} alt="Transaction Icon" />
                    </div>
                    <div className="flex flex-col gap-3">
                      <Heading
                        as="h3"
                        fontSize={{
                          base: "22px",
                          lg: "26px",
                        }}
                        textColor={"#040A0D"}
                      >
                        {formatToNaira(totalBudget, 2)}
                      </Heading>
                      <p className="text-base lg:text-lg font-normal font-[Inter] text-[#464646]">
                        Total Budget
                      </p>
                    </div>
                  </div>
                )}

                <PortfolioBadgeSection
                  portfolio={data.proPortfolio}
                  editMode={canEdit}
                />
              </div>
              <div className="col-span-1 lg:col-span-2 border-l flex flex-col">
                <div className="p-6 flex flex-col gap-4 border-b items-start">
                  <PortfolioSectionHeading>Profile</PortfolioSectionHeading>

                  <article className="flex flex-col gap-4 font-[Inter]">
                    {data.proPortfolio.overview ? (
                      data.proPortfolio.overview
                        .split("\n\n")
                        .map((para, index) => <p key={index}>{para}</p>)
                    ) : (
                      <p className="text-gray-500 font-[Inter]">
                        No overview provided
                      </p>
                    )}
                  </article>
                </div>
                {toggles?.enable_portfolio_section_on_pro_portfolio &&
                  !!portfolioMedia.length && (
                    <div className="border-b">
                      <div
                        className={clsx("p-6 flex items-start w-full", {
                          "justify-between": canEdit,
                        })}
                      >
                        <PortfolioSectionHeading>
                          Gallery
                        </PortfolioSectionHeading>

                        {canEdit && (
                          <div className="flex items-center">
                            <Button
                              variant="outline"
                              leftIcon={<Plus className="w-6 h-6" />}
                              colorScheme="flatteredFlamingo"
                              onClick={() =>
                                setShowPortfolioImageUploadModal(true)
                              }
                            >
                              Add
                            </Button>
                          </div>
                        )}
                      </div>
                      <div
                        className={`w-full px-6 ${
                          portfolioMedia.length < 1 ? "py-4" : "py-12"
                        }`}
                      >
                        {portfolioMedia.length >= 3 ? (
                          <Slider
                            dots={false}
                            infinite={true}
                            speed={3000}
                            slidesToShow={3}
                            slidesToScroll={1}
                            autoplay={true}
                            autoplaySpeed={3000}
                            pauseOnHover={true}
                            {...carouselSettings}
                          >
                            {portfolioMedia.map((media, index) => (
                              <div
                                key={index}
                                className="w-[54px] h-[190px] px-4"
                              >
                                <img
                                  src={media}
                                  alt="Portfolio Media"
                                  width={"fit"}
                                />
                              </div>
                            ))}
                          </Slider>
                        ) : portfolioMedia.length > 0 ? (
                          <div className="grid grid-cols-2 gap-4">
                            {portfolioMedia.map((media, index) => (
                              <div
                                key={index}
                                className="w-[254px] h-[150px] px-2"
                              >
                                <img src={media} alt="Portfolio Media" />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="text-gray-500">
                            No portfolio images provided
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                {!!data.proPortfolio.certifications.length && (
                  <div className="p-6 flex flex-col items-start gap-4 border-b ">
                    <div
                      className={clsx("flex items-center w-full", {
                        "justify-between": canEdit,
                      })}
                    >
                      <PortfolioSectionHeading>
                        Certifications
                      </PortfolioSectionHeading>

                      {canEdit && (
                        <div className="flex items-center">
                          <Button
                            variant="outline"
                            leftIcon={<Plus className="w-6 h-6" />}
                            colorScheme="flatteredFlamingo"
                            onClick={() =>
                              setShowCertificationCreateModal(true)
                            }
                          >
                            Add
                          </Button>
                        </div>
                      )}
                    </div>

                    {data.proPortfolio.certifications.length ? (
                      <Certifications
                        items={data.proPortfolio.certifications}
                        editable={canEdit}
                      />
                    ) : (
                      <p className="text-gray-500">
                        No certifications provided
                      </p>
                    )}
                  </div>
                )}
                {!!data.proPortfolio.educationEntries.length && (
                  <div className="p-6 flex flex-col gap-4 border-b items-start">
                    <PortfolioSectionHeading>Education</PortfolioSectionHeading>
                    <EducationEntries
                      items={data.proPortfolio.educationEntries}
                      editable={canEdit}
                    />
                  </div>
                )}

                {!!data.proPortfolio.workHistory.length && (
                  <div className="p-6 flex flex-col gap-4 border-b items-start">
                    <WorkHistory
                      items={data.proPortfolio.workHistory}
                      editable={canEdit}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Stack>
      </HStack>
    </>
  );
}
