import React from "react";
import { MdContentCopy } from "react-icons/md";

const CopyButton = ({ text }: { text: string }) => {
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  return copied ? (
    <p>Copied!</p>
  ) : (
    <MdContentCopy
      className="text-xl text-primary-300 cursor-pointer hover:text-primary-500"
      onClick={handleCopy}
    />
  );
};

export default CopyButton;
