import React from "react";
import { Check, Share } from "lucide-react";
import {
  Badge,
  Divider,
  Heading,
  HStack,
  Image,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  Link as ChakraLink,
  Button,
} from "@chakra-ui/react";
import { gql } from "../../../../../__generated__";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { format } from "date-fns";
import { formatToNaira } from "../../../../../helpers/utils/funtions";
import {
  ArtisanInvoiceQuery,
  ArtisanInvoiceStatus,
} from "../../../../../__generated__/graphql";
import RequestFailed from "../../../../../_components/RequestFailed";
import { Link } from "react-router-dom";
import WhiteLogo from "../../../../../assets/logos/white.svg";
import { Helmet } from "react-helmet";
import { logEvent } from "../../../../../helpers/analytics";
import { FaFacebookF, FaTwitter } from "react-icons/fa6";
import clsx from "clsx";
import CopyButton from "../../../../../_components/CopyButton";

const getInvoiceBadgeProps = (status: ArtisanInvoiceStatus) => {
  switch (status) {
    case ArtisanInvoiceStatus.Paid:
      return {
        colorScheme: "green",
        text: "Paid",
        icon: Check,
      };
    case ArtisanInvoiceStatus.Pending:
      return {
        colorScheme: "yellow",
        text: "Awaiting Payment",
      };
    case ArtisanInvoiceStatus.Cancelled:
      return {
        colorScheme: "red",
        text: "Cancelled",
      };
  }
};

const GET_ARTISAN_INVOICE = gql(
  `query ArtisanInvoice($invoiceId: String!) {
    artisanInvoice(invoiceId: $invoiceId) {
      createdAt
      status
      invoiceNumber
      labor
      materials {
        unitCost
        quantity
        name
      }
      client {
        name
        address
        phoneNumber
      }
      user {
        business {
          address
          name
        }
        firstName
        lastName
        pro {
          associatedLaborHackEmail
          paymentProviderInformation {
          defaultAccountNumber
          id
          providerData {
            accountNumber
            providers {
              bankInformation {
                accountName
                accountNumber
                bankName
              }
            }
          }
        }
        }
      }
      task {
        description
        name
      }
    }
  }`
);

export default function ProInvoice() {
  const { handle, id } =
    useParams<{
      handle: string;
      id: string;
    }>();

  const [showShareModal, setShowShareModal] = React.useState(false);

  const { data, loading, error } = useQuery(GET_ARTISAN_INVOICE, {
    variables: {
      invoiceId: id,
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const [proHandle, _] =
    data?.artisanInvoice?.user?.pro?.associatedLaborHackEmail.split("@") || [];

  if (!data?.artisanInvoice || proHandle !== handle) {
    return <RequestFailed text="Invoice does not exist" />;
  }

  const total = data.artisanInvoice.materials.reduce(
    (acc, material) => acc + material.unitCost * material.quantity,
    data.artisanInvoice.labor
  );

  let bankDetails: NonNullable<
    NonNullable<
      NonNullable<ArtisanInvoiceQuery["artisanInvoice"]>["user"]["pro"]
    >["paymentProviderInformation"]
  >["providerData"][number]["providers"][number]["bankInformation"] = null;

  if (data.artisanInvoice.user.pro?.paymentProviderInformation) {
    const { defaultAccountNumber, providerData } =
      data.artisanInvoice.user.pro?.paymentProviderInformation;

    if (defaultAccountNumber) {
      const defaultAccount = providerData.find(
        (account) => account.accountNumber === defaultAccountNumber
      );

      if (defaultAccount) {
        const defaultProvider = defaultAccount.providers.find(
          (provider) => provider.bankInformation
        );

        if (defaultProvider) {
          bankDetails = defaultProvider.bankInformation;
        }
      }
    }
  }

  const url = window.location.href;

  const sharePrompt = `View invoice #${
    data.artisanInvoice.invoiceNumber
  } from ${
    data.artisanInvoice.user.business?.name ||
    `${data.artisanInvoice.user.firstName} ${data.artisanInvoice.user.lastName}`
  }. Powered by LaborHack`;

  const handleShare = () => {
    logEvent("artisan_assist/invoice_share_clicked");

    if (!data.artisanInvoice) return;

    /**
     * Open the Web Share API
     */
    if (navigator.share) {
      navigator
        .share({
          title: `Invoice #${data.artisanInvoice.invoiceNumber} | Powered By LaborHack`,
          text: sharePrompt,
          url,
        })
        .then(() => {
          logEvent("artisan_assist/invoice_share_success");
        })
        .catch((error) => {
          if (error.name === "AbortError") {
            return;
          }

          logEvent("artisan_assist/invoice_share_failed", {
            error: error.message,
          });

          setShowShareModal(true);
        });
    } else {
      setShowShareModal(true);
    }
  };

  const { colorScheme, text } = getInvoiceBadgeProps(
    data.artisanInvoice.status
  );

  const pageTitle = `Invoice #${data.artisanInvoice.invoiceNumber} | Powered By LaborHack`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content={`Invoice #${data.artisanInvoice.invoiceNumber} from ${
            data.artisanInvoice.user.business?.name ||
            `${data.artisanInvoice.user.firstName} ${data.artisanInvoice.user.lastName}`
          } for ${data.artisanInvoice.client.name}. Total: ${formatToNaira(
            total
          )}. Powered by LaborHack`}
        />
      </Helmet>
      <Modal
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        isCentered
        closeOnOverlayClick
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share Profile</ModalHeader>
          <VStack pb={8} px={6} alignItems="start">
            <HStack>
              <ChakraLink
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  sharePrompt
                )}`}
                isExternal
              >
                <Button
                  rightIcon={<FaTwitter />}
                  colorScheme="twitter"
                  variant="solid"
                  onClick={() =>
                    logEvent("artisan_assist/invoice_twitter_share_clicked")
                  }
                >
                  Tweet
                </Button>
              </ChakraLink>
              <ChakraLink
                href={
                  "https://www.facebook.com/sharer/sharer.php?u=" +
                  encodeURIComponent(url)
                }
                isExternal
              >
                <Button
                  rightIcon={<FaFacebookF />}
                  colorScheme="facebook"
                  variant="solid"
                  onClick={() =>
                    logEvent("artisan_assist/invoice_facebook_share_clicked")
                  }
                >
                  Post to Facebook
                </Button>
              </ChakraLink>
            </HStack>
          </VStack>
        </ModalContent>
      </Modal>
      <div className="max-w-4xl mx-auto bg-white text-foreground">
        <div className="flex flex-col border-b p-6 w-full">
          <div className="flex justify-between items-start w-full py-2">
            <Heading className="text-3xl font-semibold proportional-nums">
              Invoice #{data.artisanInvoice.invoiceNumber}
            </Heading>
            <div className="flex flex-col md:flex-row items-end md:items-center gap-4">
              <Badge
                fontSize={{
                  base: 12,
                  sm: 14,
                }}
                paddingX={2}
                colorScheme={colorScheme}
              >
                {text}
              </Badge>
              <Button
                leftIcon={<Share className="w-5 h-5" />}
                colorScheme="prussianBlue"
                size="lg"
                variant="ghost"
                onClick={handleShare}
              >
                Share Invoice
              </Button>
            </div>
          </div>
        </div>

        <div className="grid sm:grid-cols-2 border-b">
          <div className="border-r">
            <div className="p-4 md:p-6 border-b">
              <p className="text-sm text-muted-foreground">Issued On</p>
              <p className="font-medium">
                {format(
                  new Date(data.artisanInvoice.createdAt),
                  "MMMM dd, yyyy"
                )}
              </p>
            </div>
            <div className="p-4 md:p-6">
              <p className="text-sm text-muted-foreground">From</p>
              <div className="font-medium">
                <p>
                  {data.artisanInvoice.user.business?.name ||
                    `${data.artisanInvoice.user.firstName} ${data.artisanInvoice.user.lastName}`}
                </p>
                {data.artisanInvoice.user.business && (
                  <p>{data.artisanInvoice.user.business?.address}</p>
                )}
                <p>Nigeria</p>
              </div>
            </div>
          </div>
          <div>
            <div className="p-4 md:p-6 border-b">
              <p className="text-sm text-muted-foreground">Due On</p>
              <p className="font-medium">
                {format(
                  new Date(data.artisanInvoice.createdAt),
                  "MMMM dd, yyyy"
                )}
              </p>
            </div>
            <div className="p-4 md:p-6">
              <p className="text-sm text-muted-foreground">To</p>
              <div className="font-medium">
                <p>{data.artisanInvoice.client.name}</p>
                <p>{data.artisanInvoice.client.address}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="border-b p-4 md:p-6">
          <p className="text-sm text-muted-foreground">Task</p>
          <p className="font-medium">{data.artisanInvoice.task.name}</p>
        </div>

        <div className="space-y-4 p-4 md:p-6">
          <Heading className="!text-xl font-semibold">Items</Heading>
          <div className="border">
            <div className="grid grid-cols-2 md:grid-cols-4 p-4 border-b text-sm font-medium">
              <div>Description</div>
              <div className="text-right hidden md:block">Qty</div>
              <div className="text-right hidden md:block">Unit</div>
              <div className="text-right">Amount</div>
            </div>
            {data.artisanInvoice.materials.map((material) => (
              <div
                className="grid grid-cols-2 md:grid-cols-4 px-4 py-2"
                key={material.name}
              >
                <div>{material.name}</div>
                <div className="text-sm md:text-base text-gray-500 md:text-gray-900 md:text-right col-start-1 md:col-start-2">
                  <span className="md:hidden">Qty: </span>
                  {material.quantity}
                </div>
                <div className="text-sm md:text-base text-gray-500 md:text-gray-900 md:text-right col-start-1 md:col-start-3">
                  <span className="md:hidden">Unit: </span>
                  {formatToNaira(material.unitCost)}
                </div>
                <div className="text-right md:col-start-4 row-start-2 col-start-2 md:row-start-1">
                  {formatToNaira(material.unitCost * material.quantity)}
                </div>
              </div>
            ))}
            <div className="grid grid-cols-2 md:grid-cols-4 px-4 py-2">
              <div>Labor</div>
              <div className="text-right col-start-4">
                {formatToNaira(data.artisanInvoice.labor)}
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="space-y-1 px-4">
              <div className="flex justify-between gap-8">
                <p className="text-muted-foreground">Total</p>
                <p className="font-medium">{formatToNaira(total)}</p>
              </div>
            </div>
          </div>
        </div>

        <Divider />

        {bankDetails && (
          <div className="space-y-2 p-4 md:p-6">
            <div className="flex items-center gap-2">
              <Heading as="h3" className="!text-xl font-semibold">
                Bank Details
              </Heading>
              <CopyButton
                text={`Bank Name: ${bankDetails.bankName}\nAccount Name: ${bankDetails.accountName}\nAccount Number: ${bankDetails.accountNumber}`}
              />
            </div>

            <div className="grid sm:grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-muted-foreground">Bank Name</p>
                <p className="font-medium">{bankDetails.bankName}</p>
              </div>
              <div>
                <p className="text-sm text-muted-foreground">Account Name</p>
                <p className="font-medium">{bankDetails.accountName}</p>
              </div>
              <div className="flex items-center gap-2">
                <div>
                  <p className="text-sm text-muted-foreground">
                    Account Number
                  </p>
                  <p className="font-medium">{bankDetails.accountNumber}</p>
                </div>
                <CopyButton text={bankDetails.accountNumber} />
              </div>
            </div>
          </div>
        )}

        <div className="space-y-2 p-4 md:p-6 bg-flatteredFlamingo-500">
          <div className="flex flex-col gap-2">
            <p className="font-[AeonikPro] font-semibold text-white">
              Powered By
            </p>
            <Link to="/">
              <Image
                src={WhiteLogo}
                alt="LaborHack"
                height="24px"
                width="174px"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
