import { Heading, IconButton, useDisclosure, Tag } from "@chakra-ui/react";
import clsx from "clsx";
import { Edit2, ShieldCheck } from "lucide-react";
import React from "react";
import { EducationLevel, PortfolioEducation } from "../../types";

interface EducationProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  item: PortfolioEducation;
  editable?: boolean;
}

const educationLevelsMap = {
  [EducationLevel.KCSE]: "Kenya Certificate of Secondary Education",
  [EducationLevel.SSCE]: "Senior Secondary Certificate Examination",
  [EducationLevel.OND]: "Ordinary National Diploma",
  [EducationLevel.HND]: "Higher National Diploma",
  [EducationLevel.BACHELORS]: "Bachelor's Degree",
  [EducationLevel.MASTERS]: "Master's Degree",
  [EducationLevel.DOCTORATE]: "Doctorate Degree",
};

export function EducationItem({ item, className, editable }: EducationProps) {
  const { isOpen, onOpen } = useDisclosure();

  const { id, schoolName, fieldOfStudy, qualification, verified } = item;

  return (
    <>
      <div
        className={clsx(" grid grid-cols-[1fr,32px] border-b py-4", className)}
      >
        <div className={clsx("flex flex-col gap-4 ")}>
          <div className="flex flex-col gap-2 items-start">
            <div className="flex flex-col lg:flex-row items-start lg:items-center  gap-1">
              <Heading as="h4" fontSize={18} className="font-medium m-0">
                {schoolName}
              </Heading>

              {verified && (
                <div className="flex  items-center gap-1">
                  <ShieldCheck className="fill-brand-500 stroke-white" />
                  <p>Verified</p>
                </div>
              )}
            </div>

            <p>{`${educationLevelsMap[qualification]}, ${fieldOfStudy}`}</p>
          </div>
        </div>
        {!!id && editable && (
          <div className="flex flex-col">
            <IconButton
              colorScheme="earthRed"
              variant="ghost"
              aria-label="Edit Work History"
              icon={<Edit2 className="w-5 h-5" />}
              onClick={onOpen}
            />
          </div>
        )}
      </div>
    </>
  );
}
