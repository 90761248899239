import React, { Suspense, lazy, useEffect } from "react";
import { Switch, Redirect, RouteProps, Route } from "react-router-dom";
import { userContext } from "../../../helpers/userContext";
import { components as ResetPasswordComponent } from "./../../reset-password";
import { useAuth, useAuth0ApolloClient, useQuery } from "hooks";
import { components as ContactComponents } from "../../../pages/contact";
import { components as CovidComponents } from "../../../pages/our-covid-response";
import { components as NotFoundPageComponents } from "../../notfound";
import { components as RegisterComponents } from "../../register";
import {
  ApplicationConfig,
  useApplicationConfig,
} from "context/ApplicationConfig";
import Invoice from "../../../pages/invoice";
import ScrollToTop from "../../../_components/ScrollToTop";
import Terms from "../../../pages/terms";
import Privacy from "../../../pages/privacy";
import CovidGuidelines from "pages/covid-guidelines";
import CovidSelfDeclaration from "pages/covid-self-declaration";
import firebase from "helpers/firebase";
import Auth from "../../../pages/auth";
import Hire from "pages/hire";
import { Landing } from "../../../pages/landing";
import OfflinePayments from "pages/offline-payments";
import ActivateBusiness from "../../../pages/activate-business";
import Business from "../../../pages/business";
import { ProTermsOfUse } from "../../../pages/pro-terms-of-use";
import Carousel from "../../../_components/Carousel";
import Accordion from "../../../_components/Accordion";
import Artisans from "../../../pages/artisans";
import { FAQ } from "../../../pages/faqs/FAQ";
import Navbar from "_components/Navbar/Navbar";
import { HomeOwners } from "pages/homeowners/HomeOwners";
import AboutUs from "../../../pages/about-us";
import Cookie from "../../../pages/cookie";
import PrivateRoute from "../../../_components/PrivateRoute";
import { client as firebaseClient } from "../../../helpers/initApollo";
import { ApolloProvider } from "@apollo/client";
import { HireProInLocation, HireProForAService } from "pages/hire-a-pro";
import SubscriptionsLandingPage, {
  CleaningSubscriptionsLandingPage,
  AirConditioningSubscriptionsLandingPage,
  FumigationSubscriptionsLandingPage,
} from "pages/subscriptions";
import FixMePage from "pages/fixme";
import Pros from "../../../pages/pros";
import { ChatBubble } from "../../../_components/chat-bubble";

// lazy loaded components
const Dashboard = lazy(() => import("pages/dashboard"));

///Deconstructed Components
const { ResetPassword } = ResetPasswordComponent;
const { ContactUs } = ContactComponents;
const { Covid } = CovidComponents;
const { NotFoundPage } = NotFoundPageComponents;
const { Register } = RegisterComponents;

export const App = () => {
  const authResponse = useAuth();
  const applicationConfig = useApplicationConfig();

  const { client: auth0Client, loading } = useAuth0ApolloClient();

  const { toggles } = applicationConfig;

  useEffect(() => {
    if (!authResponse.initializing && !authResponse.user) {
      firebase.auth().signInAnonymously();
    }
  }, [authResponse]);

  // store marketing tag in localstorage
  const query = useQuery();
  const marketingTag = query.get("marketing_tag");
  const leadSource = query.get("lead_source");
  marketingTag && sessionStorage.setItem("marketing_tag", marketingTag);
  leadSource && sessionStorage.setItem("lead_source", leadSource);

  const client = toggles?.use_auth0_explicitly ? auth0Client : firebaseClient;

  if (loading || applicationConfig.loading) return <div>loading...</div>;

  if (!client) return <div>Error creating apollo client</div>;

  return (
    <ApolloProvider client={client}>
      <ApplicationConfig.Provider value={applicationConfig}>
        <userContext.Provider value={authResponse}>
          <ScrollToTop />
          <Suspense fallback={<div>loading...</div>}>
            <Switch>
              <Route path="/carousel">
                <Carousel />
              </Route>
              <Route path="/accordion">
                <div className="max-w-7xl">
                  <Accordion />
                </div>
              </Route>
              <Route path="/activate-business">
                <ActivateBusiness />
              </Route>
              <Route
                path="/activate-business-client"
                component={({ location }: RouteProps) => {
                  {
                    /* route changed but previously sent emails still had this url */
                  }
                  return (
                    <Redirect
                      to={{ ...location, pathname: "/activate-business" }}
                    />
                  );
                }}
              />
              <Route path="/offlinePayments/:invoiceId">
                <OfflinePayments />
              </Route>
              <Route path="/auth" component={Auth} />

              <Route path="/dashboard">
                <Dashboard />
              </Route>

              <Route path="/invoice/:id">
                <Invoice />
              </Route>

              <Route path="/index.html">
                <Redirect to="/" />
              </Route>
              <Route path="/fixme">
                <Navbar colorScheme="flatteredFlamingo" />
                <FixMePage />
              </Route>
              <Route path="/pros/@:handle">
                <Pros />
              </Route>
              <Route path="/">
                <ChatBubble />

                <Navbar />

                <Switch>
                  <Route exact path="/">
                    <Landing />
                  </Route>

                  <Route path="/hire-a-pro/:artisan?/services/:service">
                    <HireProForAService />
                  </Route>
                  <Route path="/hire-a-pro/:artisan?/:location?">
                    <HireProInLocation />
                  </Route>
                  {/* <Route path="/subscriptions/cleaning">
                    <CleaningSubscriptionsLandingPage />
                  </Route> */}
                  <Route path="/subscriptions/air-conditioning">
                    <AirConditioningSubscriptionsLandingPage />
                  </Route>
                  <Route path="/subscriptions/fumigation">
                    <FumigationSubscriptionsLandingPage />
                  </Route>
                  <Route path="/subscriptions">
                    <SubscriptionsLandingPage />
                  </Route>

                  <Route exact path="/about">
                    <AboutUs />
                  </Route>
                  <Route exact path="/faqs">
                    <FAQ />
                  </Route>
                  <Route exact path="/artisans">
                    <Artisans />
                  </Route>

                  <Route path="/register">
                    <Register />
                  </Route>

                  <Route exact path="/pro-terms">
                    <ProTermsOfUse />
                  </Route>
                  <Route exact path="/certifications">
                    <Redirect to={"/artisans"} />
                  </Route>
                  <Route exact path="/business">
                    <Business />
                  </Route>
                  <Route path="/homeowners">
                    <HomeOwners />
                  </Route>
                  <PrivateRoute path="/hire">
                    <Hire />
                  </PrivateRoute>
                  <Route exact path="/covid-guidelines">
                    <CovidGuidelines />
                  </Route>
                  <Route exact path="/covid-self-declaration">
                    <CovidSelfDeclaration />
                  </Route>
                  <Route exact path="/terms">
                    <Terms />
                  </Route>
                  <Route exact path="/privacy">
                    <Privacy />
                  </Route>
                  <Route path="/reset-password">
                    <ResetPassword />
                  </Route>
                  <Route path="/contact">
                    <ContactUs />
                  </Route>
                  <Route path="/our-covid-response">
                    <Covid />
                  </Route>
                  <Route path="/cookie">
                    <Cookie />
                  </Route>
                  {/** links from old site */}
                  <Route path="/on-demand">
                    <Redirect to={"/homeowners"} />
                  </Route>
                  <Route path="/services/for-homeowners">
                    <Redirect to={"/homeowners"} />
                  </Route>
                  <Route path="/services/for-businesses">
                    <Redirect to={"/business"} />
                  </Route>
                  <Route path="/services/for-land-lords">
                    <Redirect to={"/business"} />
                  </Route>
                  <Route path="/services/for-projects">
                    <Redirect to={"/business"} />
                  </Route>
                  <Route path="/services/for-artisans">
                    <Redirect to={"/artisans"} />
                  </Route>

                  <Route path={"*"}>
                    <Landing />
                  </Route>
                </Switch>
              </Route>
            </Switch>
          </Suspense>
        </userContext.Provider>
      </ApplicationConfig.Provider>
    </ApolloProvider>
  );
};
