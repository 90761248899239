import React, { PropsWithChildren } from "react";
import { Heading, HeadingProps } from "@chakra-ui/react";
import clsx from "clsx";

interface PortfolioSectionHeadingProps extends HeadingProps {}

export const PortfolioSectionHeading = ({
  children,
  className,
  ...props
}: PropsWithChildren<PortfolioSectionHeadingProps>) => {
  return (
    <Heading
      as="h3"
      className={clsx(
        "font-medium text-[#040A0D] !text-lg lg:!text-xl",
        className
      )}
      {...props}
    >
      {children}
    </Heading>
  );
};
