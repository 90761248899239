import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import clsx from "clsx";
import { isBefore } from "date-fns";
import { useMemo, useState } from "react";
import { EducationItem } from "./education-item";
import { PortfolioEducation } from "types";
import { logEvent } from "helpers/analytics";
import React from "react";

const itemsPerPage = 3;

interface EducationEntriesProps {
  items: PortfolioEducation[];

  editable?: boolean;
}

const constructKey = (item: PortfolioEducation) => {
  return item.schoolName + item.qualification + item.fieldOfStudy;
};

export function EducationEntries({
  items: unorderedItems,
  editable,
}: EducationEntriesProps) {
  const items = useMemo(() => {
    return unorderedItems.slice().sort((a, b) => {
      const dateComparison = isBefore(
        new Date(a.createdAt),
        new Date(b.createdAt)
      )
        ? 1
        : -1;

      return dateComparison;
    });
  }, [unorderedItems]);

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    logEvent("pro_portfolio/education_entries_page_change", { page });
    setCurrentPage(page);
  };

  const currentPageItems = items.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="flex flex-col gap-8 w-full">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-4">
          {currentPageItems.map((item, index, array) => (
            <EducationItem
              key={constructKey(item)}
              item={item}
              className={clsx(
                index === array.length - 1 && totalPages === 1 && "border-b-0"
              )}
              editable={editable}
            />
          ))}
        </div>
      </div>

      {totalPages > 1 && (
        <div className="flex justify-end items-center gap-4">
          <Button
            isDisabled={currentPage === 1}
            leftIcon={<ChevronLeftIcon />}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </Button>

          <p>
            {currentPage} of {totalPages}
          </p>

          <Button
            isDisabled={currentPage === totalPages}
            rightIcon={<ChevronRightIcon />}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
}
